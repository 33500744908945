import { GLAccountMapping, SimpleGLAccountMapping, OptionalService } from '../Models';

export interface IOrganisationFacilityService {
    getGLAccountMappings: () => ng.IPromise<Array<GLAccountMapping>>;
    saveGLAccountMappings: (glAccountMappings: Array<GLAccountMapping>) => ng.IPromise<any>;
    getSundryChargesGLAccountMappings: () => ng.IPromise<Array<SimpleGLAccountMapping>>;
    getOptionalServices: () => ng.IPromise<Array<OptionalService>>;
    saveOptionalServices: (optionalServices: Array<OptionalService>) => ng.IPromise<any>;
    getAllOrganisationFacilities: (excludeCurrentFacility: boolean) => ng.IPromise<Array<any>>;
    deleteAccount : (optionalServices: Array<OptionalService>) => ng.IPromise<any>;
}
